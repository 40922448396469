//Load bootstrap (trimmed version)
@import "bootstrap/bootstrap" ;

@import "index.scss";
@import "updates.scss";
@import "app.scss";
@import "icons.scss";

.row .col 
{ 
    // Override for index.css .col > this should be applied to the mixin. When .col is combined with specified widths (e.g. .col-lg-3) in other rows the result is inconsistent
    margin-right: 0; 
}

.auth-inner-register {
    min-height: 250px;
}

// Text overflow clipping
.text-clip {
    overflow: hidden;
    text-overflow: ellipsis;
}

// Force styling back to bootstrap defaults (index.scss messes up these styles)
.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: $font-size-base;
}
.btn-danger i {
    font-weight: 100;
    color: white;
}

// Fix form-control and .btn within form groups
.form-group-fix {
    margin: 20px 0px;

    .input-group {
        padding-top: 10px;
    }
    .form-control {
        margin: 0px !important;
    }
    .input-group-append {
        .btn {
            padding: 0.25rem 0.5rem;
            font-size: $font-size-base;
            background-color: transparent;
            border-top: none;
            border-left: none;
            border-right: none;

            &:focus {
                box-shadow: none;
            }
        }
        .btn i {
            font-weight: 100;
            color: $gray-800;
        }
    }
}

//Override default bootstrap styling
#menu.dropdown-menu.isopen {
    border: none;
    -webkit-transform: none !important;
    transform: none !important;
    top: 116px !important;
}

// Spinner needs some stylng reset compare to the button
.btn .spinner-border {
    font-size: 1.4rem;
    vertical-align: initial;
    line-height: initial;
    margin: 0 12px;
}

// Styling for buttons in menu to be the same as links
.menu-dropdown button.btn-link {
    @extend a;

    font-size: 30px;
    border-width: 0;
    padding: 0;
    height: auto;
    line-height: 22px;

    &:hover {
        @extend a:hover;
    }
}

button.btn-link:hover {
    @extend a:hover;
}

// Base style sheet does not set a (minimum) width for the content; allowing collapse to very small block
@media only screen and (min-width: 950px) {
    .bg-portal-layout {
        min-width: 800px;
        min-height: 300px;
    }
}
@media only screen and (min-width: 1300px) {
    .bg-portal-layout {
        min-width: 1100px;
    }
}
@media only screen and (max-width: 1500px) {
    //Flexible tab list on application page
    .tab-list {
        display: inline-block;

        .tab-list-item::after {
            content: '';
        }
    }
    .my-flex-container-column {
        clear:both;
    }

    //2 links below menu
    .d-flex .ml-auto {
        a.link-portal, a.link-myapplication {
            background-image: none;
            padding: 14px 10px;
            display: inline-block;
        }
    }

    //new application link
    .title-status {
        display: flex;
    }
}
@media only screen and (max-width: 950px) {
    div.layout-portal-base {
        margin-left: 20px;
        margin-right: 20px;
    }
    .bg-portal-layout {
        width: 100%;
    }

    // 
    .name-user-contacs {
        display: none;
    }

    
    //2 links below menu
    .d-flex .ml-auto {
        a.link-portal, a.link-myapplication {
            display: none;
        }
    }
    .d-flex .my-flex-item {
        .menu-myapplication, .menu-mydetail {
            display: none;
        }
    }
}

@media only screen and (max-width: 600px) {
    .title-login {
        padding: 12px 10px 0 50px;
    }
    
    div.layout-portal-base {
        margin-left: 0;
        margin-right: 0;
    }
    .d-flex .my-flex-item .portal-title {
        display: none;
    }

    #menu.dropdown-menu.isopen {
        top: 76px !important;
        right:0;
        left: auto !important;
    }
}

.bg-portal-layout {
    // position set to contain the loading indicator
    position: relative;

    // main style sheet sets this to 0 for the .bg-graphic-small but this breaks the rest of the page
    padding-right: 40px;

    // Replaces the bottom right hand image with a less complicated positioning.
    &.with-bottom {
        background: white url(img/graphic.png) no-repeat bottom right;
        padding-bottom: 20px;

        // if these are still present just hide them
        .bg-graphic-small-openreq, .bg-graphic-small-contacs {
            display: none;
        }
    }
}


// Not mapped in index.css
b, strong {
    font-family: 'Calibri Bold';
}

.page-load-indicator {
    position: absolute;
    right: 10px;
    bottom: 10px;
}

// move margin on top-right sub navigation so it correctly aligns to the right
a.link-portal, .menu-dropdown button.link-portal.btn-link, a.link-myapplication, .menu-dropdown button.link-myapplication.btn-link {
    margin-right: 0;
    margin-left: 10px;
}

//Fix currency
.currency-field {
    .input-group-text {
        background: none;
        border: initial;
        border-bottom: 1px solid #707070;
    }
}
// Fix label display issues (fix issues introduced by index.scss)
.form-label {
    position: initial;
    display: inline-block;
}
input.form-control, textarea.form-control, select.form-control {
    margin: 0 !important;
}

// Map login box custom styling to cascading style (no special classes needed)
.login-checkbox {
    .custom-checkbox {
        width: 300px;
        float: left;
        font-size: 22px;
        margin-left: 10px;
        
        position: relative;
        display: block;
        min-height: 1.5rem;
        padding-left: 1.5rem;
    }

    .custom-control-label::before {
        border: 1px solid #EDEDED;
    }

    .custom-control-label::before {
        border-radius: 0px;
        color: #EDEDED;
    }

    .custom-control-label::before {
        position: absolute;
        top: -3px;
        left: -34px;
        display: block;
        width: 25px;
        height: 25px;
        pointer-events: none;
        content: "";
        background-color: #fff;
        border: #EDEDED solid 1px;
    }

    .custom-control-label::before {
        transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .custom-control-label::after {
        position: absolute;
        top: -3px;
        left: -34px;
        display: block;
        width: 25px;
        height: 25px;
        content: "";
        background: no-repeat 50%/50% 50%;
    }

    .custom-control-label {
        font-weight: 100;
        color: #A5A5A5;
        font-size: 22px;
        text-align: left;
        line-height: 25px;
        font-family: Calibri Light;
    }

    .custom-control-label {
        position: relative;
        margin-bottom: 0;
        vertical-align: top;
    }
}

.nav-link{
    cursor: pointer;
}

/* validation styling for dropzone */
.file-drop.was-validated {
    .is-invalid .dropzone {
        border-color: $form-feedback-invalid-color;

        background-image: escape-svg($form-feedback-icon-invalid);
        background-repeat: no-repeat;
        background-position: right $input-height-inner-quarter center;
        background-size: $input-height-inner-half $input-height-inner-half;
        .dz-message {
            color: $form-feedback-invalid-color;
        }
    }
    .is-valid .dropzone {
        border-color: $form-feedback-valid-color;
        
        background-image: escape-svg($form-feedback-icon-valid);
        background-repeat: no-repeat;
        background-position: right $input-height-inner-quarter center;
        background-size: $input-height-inner-half $input-height-inner-half;
    }

}
// fix dropzone only the nested div is actually dropable
.dropzone {
    padding: 0px;

    &>div {
        padding: 40px 0px;
        width: 100%;
        text-align: center;
    }
}

h1 {
    font-size: 1.375em;
    color: #1F4464;
}

h2 {
    font-size: 1.3em;
    color: #1F4464;
}

// Map tooltip style to correct child
.fno-tooltip-container {
    .tooltip {
        @extend .fno-tooltip;

        //show on top of inputs
        z-index: 10;

        //override index.scss 10% limit
        max-width: 300px !important;
    }
}
.title-radio-button {
    color: #9b9b9b;
    font-weight: 500;
}