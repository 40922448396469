@font-face {
    font-family: Calibri Light;
    src: url('./fonts/Calibri\ Light.ttf');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: Calibri Regular;
    src: url('./fonts/Calibri\ Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Calibri Bold;
    src: url('./fonts/Calibri\ Bold.TTF');
    font-weight: bold;
    font-style: normal;
}

* {
    box-sizing: border-box;
}

body {
    font-family: ' Calibri Regular';
}

body {
    background: #fff;
    min-height: 100vh;
    display: flex;
    font-weight: 100;
    font-family: 'Calibri', sans-serif;
}

h1, h2, h3, h4, h5, h6, label, span {
    font-weight: 500;
    font-family: 'Calibri', sans-serif;
}

body, html, .App, #root, .auth-wrapper {
    width: 100%;
    height: 100%;
}

.App {
    text-align: center;
}

a {
    color: #1F4464;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.navbar-light {
    background-color: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.col {
    margin-right: 50px;
}

/*login*/

.auth-wrapper {
    display: inline-block;
    justify-content: center;
    flex-direction: column;
    text-align: left;
}

.auth-inner {
    width: 714px;
    margin: auto;
    background: #fff;
    box-shadow: 0px 5px 10px rgba(34, 35, 58, 0.2);
    padding: 50px 70px 125px 80px;
    transition: all .3s;
    position: relative;
    z-index: 1;
}

.auth-inner-register {
    width: 927px;
    margin: auto;
    background: #fff;
    box-shadow: 0px 5px 10px rgba(34, 35, 58, 0.2);
    padding: 20px;
    transition: all .3s;
    position: relative;
    z-index: 1;
}

.auth-wrapper .form-control:focus {
    border-color: #167bff;
    box-shadow: none;
}

.auth-wrapper h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
}

.forgot-password, .forgot-password a {
    text-align: right;
    font-size: 13px;
    padding-top: 10px;
    color: #7f7d7d;
    margin: 0;
}

.forgot-password a {
    color: #167bff;
}

.logo-login {
    background-image: url("./img/logo-login.png");
    background-repeat: no-repeat;
    float: left;
    margin: 50px 0px 0px 100px;
    width: 260px;
    height: 129px;
}

.wrap-title-login {
    width: 714px;
    margin: auto;
    margin-bottom: 85px;
}

.wrap-title-register {
    width: 927px;
    margin: auto;
    margin-bottom: 85px;
}

.title-login {
    float: left;
    clear: both;
    background: url("./img/title-login-bg.png") no-repeat left;
    width: 386px;
    height: 84px;
    color: #fff;
    padding: 15px 0px 0px 90px;
    font-size: 40px;
    font-family: Calibri Bold;
}

.title-register {
    float: left;
    clear: both;
    background: url("./img/title-login-bg.png") no-repeat left;
    width: 347px;
    height: 84px;
    color: #fff;
    padding: 15px 0px 0px 70px;
    font-size: 35px;
}

a.link-title-register {
    font-size: 35px;
    color: #1F4464;
    font-weight: 500;
    letter-spacing: -1px;
    float: right;
    margin-top: 20px;
    font-family: Calibri Bold;
}

a.link-title-login {
    font-size: 35px;
    color: #1F4464;
    font-weight: 500;
    letter-spacing: -1px;
    float: right;
    margin-top: -80px;
    font-family: Calibri Bold;
}

.red-sign {
    color: #EC443F;
}

.wrap-login {
    background: #F8F8F8;
}

.title-personal {
    font-size: 32px;
    font-weight: 500;
    color: #1F4464;
    margin-left: 15px;
    font-family: Calibri Bold;
}


input.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: 0px 15px 10px;
    font-size: 1rem;
    line-height: 1.5;
    font-size: 18px;
    color: #94A2B0 !important;
    background-color: inherit;
    background-clip: padding-box;
    border-bottom: 1px solid #707070 !important;
    border-radius: 0px !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
    margin: 20px 0px 20px !important;
}



input.form-control-visible {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: 10px;
    font-size: 1rem;
    line-height: 1.5;
    font-size: 18px;
    color: #B8C2CC !important;
    background-color: #95999d;
    background-clip: padding-box;
    border:0px;
    border-radius: 0px !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin: 20px 0px 20px !important;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: 0px 0px 10px;
    font-size: 1rem;
    line-height: 1.5;
    font-size: 18px;
    color: #94A2B0 !important;
    background-color: inherit;
    background-clip: padding-box;
    border-bottom: 1px solid #707070 !important;
    border-radius: 0px !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
    margin: 20px 0px 20px !important;
}

input.form-control-login {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: 0px 0px 10px;
    font-size: 1rem;
    line-height: 1.5;
    font-size: 25px;
    color: #94A2B0 !important;
    background-color: inherit;
    background-clip: padding-box;
    border-bottom: 2px solid #EDEDED !important;
    border-radius: 0px !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
    margin: 20px 0px 40px !important;
}

.form-control-register {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: 0px 0px 10px;
    font-size: 1rem;
    line-height: 1.5;
    font-size: 18px;
    color: #707070 !important;
    background-color: inherit;
    background-clip: padding-box;
    border-bottom: 2px solid #EDEDED !important;
    border-radius: 0px !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
    margin: 20px 0px 20px !important;
}

input.form-control-register {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: 0px 0px 10px;
    font-size: 1rem;
    line-height: 1.5;
    font-size: 18px;
    color: #707070 !important;
    background-color: inherit;
    background-clip: padding-box;
    border-bottom: 2px solid #EDEDED !important;
    border-radius: 0px !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
    margin: 20px 0px 20px !important;
}

.form-control:focus {
    border-bottom: 2px solid #1F4464 !important;
    border-radius: 0px !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
}

select.form-control:not([size]):not([multiple]) option:checked {
    background: #FCFCFC;
}

.custom-control.custom-checkbox-login {
    width: 300px;
    float: left;
    font-size: 22px;
}

/*ini*/

.form-group {
    position: relative;
    padding: 15px 0 0;
    margin-top: 10px;
    width: 50%;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0px;
    color: #EDEDED;
}

.radio-text {
    font-size: 18px;
}

.title-radio-button-ammount {
    font-size: 18px;
}

.spacing {
    height: 20px;
}

.spacing-1 {
    height: 163px;
}

.title-radio-button {
    color: #D8D8D8;
    display: inline-block;
    margin-right: 20px;
}

.custom-control-label::before {
    border: 1px solid #707070;
}

.custom-control-label {
    font-weight: 100;
    color: #9b9b9b;
    font-size: 18px;
    text-align: left;
    line-height: 25px;
    font-weight: 500;
}

.custom-file-label {
    font-weight: 400;
    line-height: 1.5;
    color: #707070;
    background-color: #fff;
    border-bottom: 1px solid #707070;
    border-radius: 0px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    position: relative;
    width: 6000%;
    top: -23px;
    left: 0px;
    padding: 0px 104px 0px 0px;
}

/*login*/

.custom-control-input-login {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

.custom-control-login.custom-checkbox {
    width: 300px;
    float: left;
    font-size: 22px;
    margin-left: 10px;
}

.custom-control-login {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}

.custom-control-label-login::before {
    border: 1px solid #EDEDED;
}

.custom-checkbox .custom-control-label-login::before {
    border-radius: 0px;
    color: #EDEDED;
}

.custom-control-label-login::before {
    position: absolute;
    top: -3px;
    left: -34px;
    display: block;
    width: 25px;
    height: 25px;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #EDEDED solid 1px;
}

.custom-control-label-login::before {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.custom-control-label-login::after {
    position: absolute;
    top: -3px;
    left: -34px;
    display: block;
    width: 25px;
    height: 25px;
    content: "";
    background: no-repeat 50%/50% 50%;
}

.custom-control-label-login {
    font-weight: 100;
    color: #A5A5A5;
    font-size: 22px;
    text-align: left;
    line-height: 25px;
    font-family: Calibri Light;
}

.custom-control-label-login {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.custom-file-label-login {
    font-weight: 400;
    line-height: 1.5;
    color: #707070;
    background-color: #fff;
    border-bottom: 1px solid #707070;
    border-radius: 0px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    position: relative;
    width: 420px;
    top: -34px;
}

a.term-link {
    float: left;
    margin: 30px 50px 100px;
    font-size: 18px;
    color: #94A2B0;
    font-family: Calibri Light;
    text-decoration: underline;
}

a.term-link:hover {
    color: #1F4464;
}

select {
    background: url(./img/select.png) no-repeat right 25%;
    -webkit-appearance: none;
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
    background: url(./img/select.png) no-repeat right top;
}

.bg-graphic-small-term {
    background: url(img/graphic.png) no-repeat;
    width: 104px;
    height: 114px;
    float: right;
    right: -12px;
    z-index: 1;
    position: relative;
    top: -72px;
}

.bg-graphic-small-mydetails {
    background: url(img/graphic.png) no-repeat;
    width: 104px;
    height: 114px;
    float: right;
    right: 7px;
    z-index: 1;
    position: relative;
    top: -121px;
}

.bg-graphic-small-budget {
    background: url(img/graphic.png) no-repeat;
    width: 104px;
    height: 114px;
    float: right;
    right: -50px;
    z-index: 1;
    position: relative;
    top: 78px;
}

.custom-control.custom-checkbox {
    width: 100%;
    float: left;
    font-size: 15px;
}

.custom-control-input-login {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

p.forgot-password.text-right {
    font-size: 22px;
    font-weight: 100;
    color: #A5A5A5;
    padding-top: 0px;
}

.btn-default-text {
    font-size: 35px;
    color: #1F4464;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: -1px;
    float: right;
    margin: 30px 100px;
    font-family: Calibri Bold;
}

.btn-default-text-back {
    font-size: 35px;
    color: #1F4464;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: -1px;
    float: left;
    margin-top: 20px;
    font-family: Calibri Bold;
}

.bg-graphic-small {
    background: url(img/graphic.png) no-repeat;
    width: 104px;
    height: 114px;
    float: right;
    margin-top: -122px;
    z-index: 1;
    position: relative;
}

.bg-graphic-large {
    background: url(img/bg-graphic.png) no-repeat;
    width: 572px;
    height: 580px;
    opacity: 0.4;
    float: right;
    margin-top: -359px;
    margin-right: 90px;
}

/*layout portal*/

.logo-portal {
    background-image: url("./img/logo-portal.png");
    background-repeat: no-repeat;
    float: left;
    margin: 20px 0px 0px 50px;
    width: 264px;
    height: 78px;
}

.layout-portal {
    background: #F3F3F3;
    margin: 0px 50px;
    padding: 20px 50px;
    display: inline-block;
}

.my-flex-container {
    margin-bottom: 5px;
}

.my-flex-container-column {
    margin-bottom: 20px;
}

.p-2 {
    padding: 0px !important;
}

.name-user {
    color: #1F4464;
    text-align: left;
    font-size: 18px;
    margin-bottom: 20px;
    font-family: calibri Bold;
}

.portal-title {
    background: #1F4464;
    color: #fff;
    padding: 10px 20px;
    font-size: 25px;
    font-weight: 500;
    margin-top: 13px;
    font-family: calibri Bold;
}

.menu-mydetail {
    background: #fff;
    color: #1F4464;
    padding: 14px 20px;
    font-size: 25px;
    margin-right: 10px;
    border-bottom: 4px solid #1F4464;
    font-family: calibri Light;
}

.menu-myapplication {
    background: #fff;
    color: #1F4464;
    padding: 13px 20px;
    font-size: 25px;
    margin-right: 10px;
    border-bottom: 4px solid #1F4464;
    font-family: calibri Light;
}

a.menu-myapplication {
    padding: 20px 20px;
    position: relative;
    top: 14px;
    font-family: Calibri Bold;
    background: #fff;
}

a.menu-mydetail {
    padding: 20px 20px;
    position: relative;
    top: 14px;
    font-family: Calibri Bold;
    background: #fff;
}

a.btn-default-project-back, a.btn-default-project-save {
    font-size: 30px;
    color: #1F4464;
    text-decoration: none;
    font-weight: 600;
    font-family: Calibri Bold;
}

.welcome {
    color: #1F4464;
    font-family: Calibri Bold;
    margin-bottom: 20px;
    font-size: 25px;
}

p {
    font-family: Calibri Light;
    line-height: 20px;
}

.bg-portal-layout {
    background: #fff;
    padding: 40px 0px 30px 40px;
    text-align: left;
    color: #707070;
    font-size: 18px;
    line-height: 25px;
    border: 8px solid #EDEDED;
    margin-top: -5px;
    font-weight: 100;
}

.footer {
    color: #A7A0A0;
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
}

/*menu top */

.menu-dropdown {
    background: url('./img/menu-bg.png') no-repeat;
    width: 260px;
    height: 129px;
    text-align: center;
    color: #fff;
    float: right;
    margin-right: 50px;
    font-size: 30px;
    padding: 35px;
}

/*my details layout*/

.layout-portal-mydetails {
    background: #F3F3F3;
    margin: auto 50px;
    padding: 20px 50px;
    clear: both;
    /*display: inline-block;
    width: 1340px;*/
}

.col-font-light {
    font-family: Calibri Light;
}

.nav-tabs .nav-link.active {
    color: #1F4464;
    padding: 10px 20px 10px 20px;
    font-size: 22px;
    border-bottom: 4px solid #1F4464;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    font-family: Calibri Bold;
}

.nav-tabs .nav-link {
    color: #A5B1B4;
    background-color: #F7F7F7;
    border-bottom: 4px solid #D1D0D0;
    font-size: 22px;
    padding: 10px 20px 10px 20px;
    font-family: Calibri Light;
}

.nav-tabs {
    border-bottom: 0px;
}

.nav-tabs .nav-link:hover {
    border-color: #fff #fff #D1D0D0 #fff;
}

.request {
    background: #1F4464;
    display: inline-block;
    padding: 10px 20px;
    margin: 20px 0px;
    color: #fff;
    font-size: 18px;
}

.title-request {
    color: #1F4464;
    font-size: 20px;
    font-weight: 500;
}

.table-border.row {
    border: 1px solid #707070;
    margin: 20px 40px 40px 0px;
    padding: 20px;
    font-size: 20px;
}

.title-table {
    color: #1F4464;
    font-size: 20px;
    font-family: Calibri Bold;
}

.title-request {
    color: #1F4464;
    font-weight: 500;
}

/*tab contacs*/

a.link-portal, a.link-myapplication {
    background-color: #fff;
    color: #1F4464;
    padding: 14px 50px;
    font-size: 20px;
    margin-right: 10px;
    border-bottom: 4px solid #1F4464;
    font-family: calibri light;
    background-image: url(./img/right-arrow-contacts.png);
    background-repeat: no-repeat;
    background-position:90% 50%;
    
}

.name-user-contacs {
    color: #1F4464;
    font-family: Calibri Bold;
    font-size: 22px;
    display: inline-block;
    margin-left: 10px;
    line-height: 20px;
}

.tabs {
    width: 100%;
}

.tab-list {
    padding-left: 0;
    cursor: pointer;
    display: inline-block;
    width: 100%;
    margin-top: 20px;
    background: #F7F7F7;
    display: flex;
}

li.tab-list-item {
    float: left;
    list-style: none;
    font-size: 22px;
    border-bottom: 4px solid #A5B1B4;
    flex: 1 1 0px;
    color: #A5B1B4;
    font-family: Calibri Light;
    padding: 10px 20px;
}

li.tab-list-item.tab-list-active {
    float: left;
    background-color: white;
    list-style: none;
    border-bottom: 4px solid #1F4464;
    color: #1F4464;
    font-family: Calibri Bold;
}


.tab-list-item::after {
    content: url(./img/right-arrow-contacts.png);
    width: 20px;
    height: 11px;
    float: right;
  }


/*contacs layout*/

.bg-contacs-layout {
    background: #fff;
    padding: 50px;
    text-align: left;
    color: #707070;
    font-size: 18px;
    line-height: 25px;
    border: 8px solid #EDEDED;
    margin-top: -5px;
    display: inline-block;
    font-family: Calibri Regular;
}

textarea.form-control-projectname.form-control {
    height: 100px;
}

.doyouwant {
    color: #707070;
    font-size: 19px;
    font-family: Calibri Light;
    margin-top: 20px;
    margin-bottom: 40px;
}

.btn-default-contacs {
    font-size: 30px;
    color: #1F4464;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: -1px;
    margin: 30px 50px;
    font-family: Calibri Bold;
}

.bg-graphic-small-contacs {
    background: url(img/graphic.png) no-repeat;
    width: 104px;
    height: 114px;
    float: right;
    right: -51px;
    z-index: 1;
    position: relative;
    top: 50px;
}

.bg-graphic-small-project {
    background: url(img/graphic.png) no-repeat;
    width: 104px;
    height: 114px;
    float: right;
    right: -88px;
    z-index: 1;
    position: relative;
    top: 30px;
}

.bg-graphic-small-openreq {
    background: url(img/graphic.png) no-repeat;
    width: 104px;
    height: 114px;
    float: right;
    right: -1px;
    z-index: 1;
    position: relative;
    top: -83px;
}

.title-contacs {
    font-size: 25px;
    font-weight: 600;
    color: #1F4464;
}

/*accordion -attachment layout*/

.card-header {
    padding: 10px 15px;
    margin-bottom: 0;
    border-bottom: 0px;
    color: #fff;
    font-size: 20px;
    text-align: left;
    cursor: pointer;
    background-color: #94A2B0 !important;
    border-radius: 0px;
    background-image: url(./img/icon-ionic-ios-arrow-down.png);
    background-position: right 50%;
    background-repeat: no-repeat;
}

.card-header:first-child {
    border-radius: 0px !important;
    padding: 10px 0px 10px 10px !important;
    border-bottom: 0px !important;
    border-top: 0px !important;
}

.accordion>.card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    padding: 0px;
}

section.container {
    max-width: 100%;
}

.card {
    margin-bottom: 10px;
    border: 0px;
    box-shadow: 0px 10px 15px -8px #707070;
    border-radius: 0px !important;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 20px 20px 0px;
    background: #fff;
}

.bg-portal-layout-term {
    background: #fff;
    padding: 40px 10px;
    margin-top: 20px;
}

.custom-checkbox-term {
    margin: 0px 45px;
    text-align: left;
    font-size: 18px;
    font-family: Calibri Light;
    color: #707070;
}

.title-attach {
    text-align: left;
    color: #1F4464;
    padding-left: 14px;
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 18px;
}

/*datepicker*/

input.react-datepicker-ignore-onclickoutside, .react-datepicker-wrapper {
    border-bottom: 1px solid #ccc;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    display: inline-block;
    width: 220px;
}

.text-datepicker {
    font-size: 18px;
    color: #707070 !important;
    float: left;
}

svg.arrow {
    stroke-width: 1px;
    fill: #fff;
    stroke: #1F4464;
}

/*dropzone style*/

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0px;
    border-width: 1px;
    border-radius: 1px;
    border-color: #ACACAC;
    border-style: dashed;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    cursor: pointer;
}

.drag {
    font-size: 18px;
    font-family: Calibri Light;
}

span.browse {
    text-decoration: underline;
    font-family: Calibri Light;
}

.upload-icon {
    background: url(./img/Icon-awesome-cloud-upload-alt.png)no-repeat;
    width: 45px;
    height: 31px;
    margin: 20px auto 0px;
}

ul.stylefiles {
    list-style: none;
    text-align: left;
    padding-left: 0px;
    /*padding: 10px 20px 10px;
    background: #F3F3F3;*/
    margin-top: 20px;
    font-size: 16px;
    font-family: Calibri Light;
}

.doc-icon {
    background: url(./img/Icon-ionic-md-document.png) no-repeat;
    float: left;
    width: 32px;
    height: 42px;
}

.delete-files {
    background: url(./img/Icon-awesome-trash.png)no-repeat;
    float: right;
    width: 22px;
    height: 25px;
}

svg.svg-inline--fa.fa-arrow-right.fa-w-14 {
    font-size: 19px;
    font-weight: 400;
    font-family: FontAwesome;
    margin-left: 10px;
}

svg.svg-inline--fa.fa-arrow-left.fa-w-14 {
    font-size: 19px;
    font-weight: 400;
    font-family: FontAwesome;
    margin-right: 10px;
}

/*datepicker style*/

.react-datepicker-wrapper {
    width: auto !important;
}

.react-datepicker__input-container {
    position: absolute !important;
    border-bottom: 1px solid #707070;
}

.form-group.form-control-projectname {
    margin-top: 50px;
}

input[type="text"] {
    border: 0px;
}

.datestitle {
    height: 0px;
    margin-top: 10px;
    color: #707070;
    font-size: 18px;
}

#hambmenu {
    position: relative;
    width: 36px;
    height: 45px;
    float: left;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    top: -54px !important;
    right: -130px !important;
}

/*input style*/

::placeholder {
    color: #707070;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #707070;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #707070;
}

i.fa.fa-euro {
    color: #94A2B0;
    float: left;
}


/*table style */
.table {
    max-width: 99% !important;
}
.title-table-project {
    color: #1F4464;
    font-size: 20px;
    font-family: Calibri Light;
    margin-bottom: 10px;
}

.title-project {
    font-size: 32px;
    font-weight: 500;
    color: #1F4464;
    font-family: Calibri Bold;
    margin-bottom: 20px;
}

.table thead th {
    border-bottom: 0px !important;
    background: #F3F3F3;
    color: #1F4464;
    font-size: 16px;
    border-right: 1px solid #F3F3F3 !important;
}


.table td {
    border-top: 0px !important;
    border-bottom: 1px solid #a5a5a5 !important;
    color: #9A9A9A;
}

.table th  {
    border: 0px !important;
}

tbody.table-content {
    font-size: 16px;
    border: 1px solid #a5a5a5 !important;
}

/*pageing style */
.pagination-sm .page-item:first-child .page-link, .pagination-sm .page-item:last-child .page-link {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    background: #fff !important;
    color: #1F4464 !important;
}

a.page-link {
    color: #1F4464 !important;
}

.page-item.active .page-link {
    background-color: #1F4464 !important;
    border-color: #1F4464 !important;
}

.page-link {
    background-color: #F3F3F3 !important;
}


/*tooltip */

.fno-tooltip {
    cursor: pointer !important;
    border: 1px solid #707070 !important;
    background: #fff !important;
    color: #1F4464 !important;
    padding: 15px !important;
    max-width: 10% !important;
    font-family: Calibri Regular !important;
}

.__react_component_tooltip::before, .__react_component_tooltip::after{
    display: none !important;
}






/*responsive layout*/

@media only screen and (min-width: 320px) {
    /* .menu-dropdown {
        background: url(./img/menumobile.png) no-repeatno-repeat left;
        width: 130px;
        height: 65px;
        float: right;
        margin-right: 0px;
        font-size: 22px;
        display: flex;
        flex-flow: row;
        line-height: 0px;
        padding-left: 15px;
    } */
}

@media only screen and (max-width: 600px) {
    /*login*/
    .wrap-login {
        background: #F8F8F8;
        margin: 0px 10px 20px 10px;
    }
    .auth-wrapper {
        padding: 10px;
    }
    .title-login {
        background: url(./img/menumobile.png) no-repeat right;
        padding: 12px 50px 0px 50px;
        width: auto;
        font-size: 30px;
    }
    .bg-graphic-large {
        width: auto;
        margin-right: 0px;
    }
    a.link-title-login {
        font-size: 30px;
    }
    .title-register {
        background: url(./img/menumobile.png) no-repeat right;
        padding: 12px 17px 0px 49px;
        width: auto;
        font-size: 30px;
    }
    .bg-graphic-large {
        float: none;
    }
    p.forgot-password.text-right {
        text-align: left !important;
        font-size: 18px;
    }
    .custom-control-label-login {
        font-size: 18px;
    }
    input.form-control-login {
        font-size: 20px;
    }
    .bg-graphic-small {
        margin-top: -132px;
    }
    .btn-default-text {
        margin: 30px 100px 30px 20px;
    }
    .wrap-title-login, .wrap-title-register {
        margin-top: 20px;
        width: 100%;
    }
    /*register*/
    a.link-title-register {
        font-size: 25px;
        color: #1F4464;
        font-weight: 500;
        letter-spacing: -1px;
        float: right;
        margin-top: 10px;
        font-family: Calibri Bold;
    }
    .auth-inner-register {
        width: 100%;
    }
    .title-personal {
        font-size: 25px;
        width: 100%;
    }
    /*portal*/
    .logo-portal, .logo-login {
        background: url(./img/logomobile.png) no-repeat left;
        width: 165px;
        height: 49px;
        margin: 10px;
        float: left;
    }
    .menu-dropdown {
        background: url(./img/menumobile.png) no-repeat right;
        width: 170px;
        height: 65px;
        float: right;
        margin-right: 0px;
        font-size: 22px;
        display: flex;
        flex-flow: row;
        line-height: 0px;
        padding-left: 50px;
    }
    /*menu style*/
    #hambmenu {
        top: -33px !important;
        right: -1px !important;
    }
    #menu li {
        font-size: 20px;
    }
    .hambclicker {
        top: 15px!important;
        right: 10px!important;
    }
    #menu {
        top: 73px !important;
        right: 49px;
    }
    .flex-row {
        flex-direction: column !important;
    }
    .mr-auto, .mx-auto {
        margin-right: initial !important;
        margin-left: initial !important;
    }
    a.menu-mydetail {
        top: -3px;
        text-align: left;
    }
    .menu-mydetail, .menu-myapplication {
        text-align: left;
    }
    .p-2 {
        display: grid;
    }
    .portal-title {
        margin-top: 2px;
    }
    .layout-portal {
        padding: 0px;
        margin: 5px;
        margin-right: 0px;
    }
    .name-user {
        margin-bottom: 0px;
        margin-top: 10px;
        margin-left: 20px;
    }
    .bg-portal-layout {
        margin-top: 5px;
        padding: 20px 0px 30px 20px;
    }
    .layout-portal-mydetails {
        width: 100%;
        padding: 4px;
        margin: 0px;
    }
    .menu-mydetail {
        margin-right: 0px;
    }
    li.tab-list-item {
        width: 100%;
        text-align: left;
    }
    li.tab-list-item.tab-list-active {
        text-align: left;
    }
    .tab-list {
        display: flex;
        flex-direction: column;
        padding-left: 5px;
    }
    .wrap-title-login {
        width: 100%;
    }
    .auth-inner {
        width: 100%;
        padding: 20px 10px 106px 10px;
    }
    .btn-default-contacs {
        margin: 20px 10px 0px 0px;
        /*display: flex;
      contain: size;*/
    }
    a.menu-myapplication {
        top: 10px;
        text-align: left;
    }
    .tab-list-active::after {
        content: url(./img/right-arrow-contacts-blue.png);
        width: 25px;
        height: 21px;
        float: none;
    }
    .bg-graphic-small-openreq {
        top: 30px;
    }
    a.term-link {
        margin: 30px 50px 10px;
        text-align: left;
    }
    .bg-graphic-small-term {
        right: -15px;
        top: 41px;
    }
    .col {
        margin-right: 20px;
    }
    .bg-graphic-small-project {
        top: 30px;
        right: -35px;
    }
    .bg-graphic-small-budget {
        right: -21px;
        top: 30px;
    }
    .bg-graphic-small-mydetails {
        background: url(img/graphic.png) no-repeat;
        width: 104px;
        height: 114px;
        float: right;
        left: 256px;
        z-index: 1;
        position: relative;
        top: -121px;
    }
    a.btn-default-project-back {
        font-size: 35px;
        bottom: 0px;
        position: absolute;
    }
    .title-contacs {
        margin-top: 50px;
    }
    .datestitle {
        display: grid;
        flex-direction: row;
    }
    
}

@media (min-width: 1280px) {
    .btn-default-text {
        //margin: 30px 0px 0px 0px;
    }
    .bg-graphic-small-budget {
        right: -140px;
        top: 95px;
    }
}

@media (min-width: 1980px) {
    .logo-login {
        margin: 100px 0px 0px 300px;
    }
    .layout-portal-mydetails {
        background: #F3F3F3;
        margin: auto 50px;
        padding: 20px 50px;
        clear: both;
    }
    .container {
        max-width: inherit;
    }
    .bg-graphic-small-project {
        right: -67px;
    }
    .bg-graphic-small-budget {
        right: -339px;
        top: 95px;
    }
}

@media (min-width: 2560px) {
    .logo-login {
        margin: 50px 0px 0px 700px;
    }
    .container {
        max-width: inherit;
    }
}


 .form-group {
    position: relative;
    padding: 15px 0 0;
    margin-top: 10px;
    width: 100%;
    font-size: 18px;
  }
  
  .form-control {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid #9b9b9b;
    outline: 0;
    font-size: 18px;
    color: #fff;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
  }

  .form-control::placeholder {
    color: transparent;
    font-size: 18px;
  }
  .form-control:placeholder-shown ~ .form-label {
    font-size: 18px;
    cursor: text;
    top: 10px;
  }
  
  .form-label {
    position: absolute;
    top: -5px;
    display: block;
    transition: 0.2s;
    font-size: 18px;
    color: #9b9b9b;
    line-height: 18px;
  }
  
  .form-control:focus {
    padding-bottom: 6px;
    border-width: 3px;
    border-color:#1F4464;
    padding-left: 15px;
  }

  .form-control:focus ~ .form-label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 18px;
    color:#1F4464;
    font-weight: 400;
  }
  
  /* reset input */
  .form-control:required, .form-control:invalid {
    box-shadow: none;
  }

  i.fa.fa-euro {
    color: #94A2B0;
    float: left;
    position: relative;
    top: 26px;
}