
/*
* # Updates for index.scss 
* This file should be cleared when index.scss is replaced
*/
.btn-default-text {
    float: right;
    margin: 20px 70px;
}

button.btn-default-contacs.btn.btn-submit {
    float: right;
    margin: 30px 70px;
}
.table-border.row {
    border: 1px solid #707070;
    margin-left: 0px;
    padding: 20px;
    font-size: 20px;
    width: 99%;
    margin-bottom: 30px;
}

.title-request {
    color: #1f4464;
    font-weight: 500;
    margin-top: 40px;
}
thead.title-table-header {
    border-right: 1px solid #F3F3F3;
}
