.errorNotification {
    border: 1px solid #dc3545;
    color: #dc3545;
    font-weight: bold;
    position: fixed;
    bottom: 5px;
    right: 5px;
    border-radius: 4px;
    padding: 2px 8px;
    //display on top of the pre-loader
    z-index: 1036;
}

dd div.long {
    max-width: 100%;
    overflow: scroll;
}